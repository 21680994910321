<template>
	<b-card no-body>
		<template v-if="!readOnly">
			<Header />
			<hr class="m-0" />
		</template>


		<div class="bg-light p-4 ">
			<b-card class="mb-4 bg-white">
				<div class="d-flex justify-content-between align-items-start">
					<h4>Customer Information</h4>
					<b-badge :variant="'success'">
						Low Risk
					</b-badge>
				</div>
				<div class="row" v-if="customer">
					<div class="col-4">
						<h6 class="mb-0">Name</h6>
						<p class="text-muted">{{ customer.firstName }} {{ customer.lastName }}</p>
					</div>
					<div class="col-4">
						<h6 class="mb-0">Adress</h6>
						<p class="text-muted">{{ customer.address1 }}</p>
					</div>
					<div class="col-4">
						<h6 class="mb-0">Date of birth</h6>
						<p class="text-muted" v-if="customer.additionalDetails">{{
							customer.additionalDetails.birthDate.split('T')[0] }}</p>
					</div>
					<div class="col-4">
						<h6 class="mb-0">City</h6>
						<p class="text-muted mb-0">{{ customer.cityName }}</p>
					</div>
					<div class="col-4">
						<h6 class="mb-0">Nationality</h6>
						<p class="text-muted mb-0" v-if="customer.additionalDetails">{{
							customer.additionalDetails.nationality
						}}</p>
					</div>
					<div class="col-4">
						<h6 class="mb-0">Marital Status</h6>
						<p class="text-muted mb-0" v-if="maritalStatus">{{ maritalStatus ? maritalStatus.name : ''
							}}</p>
					</div>
				</div>
			</b-card>
			<div class="bg-white p-4 mb-4 rounded-lg" v-if="!readOnly">

				<b-form @submit.prevent="validateMember" class="d-flex justify-content-between">
					<div class="d-flex justify-content-end">
						<p class="mb-0 text-muted">Last verification date: {{ customer.scansLastVerificationDate }}</p>
					</div>
					<div class="d-flex justify-content-end">
						<div class="btn-grp text-right d-flex align-items-end h-100">
							<b-button pill :disabled="loading" variant="primary" class="px-4 mb-0" type="submit">
								<b-spinner small v-if="loading"></b-spinner>
								<span v-else>Verify</span>
							</b-button>
						</div>
					</div>
				</b-form>

				<div class="mt-4">
					<b-form @submit.prevent="updatePepData">

						<b-form-checkbox id="pep" v-model="pep" name="pep" class="mb-3">
							Politically exposed people
						</b-form-checkbox>

						<b-form-group v-slot="{ ariaDescribedby }" class="mb-3">
							<label for="matches" class="fw-medium">Validate actual matches</label>
							<b-form-radio-group id="matches" :aria-describedby="ariaDescribedby"
								name="radio-sub-component" v-model="pepConfirm">
								<b-form-radio :value="true">True coincidence</b-form-radio>
								<b-form-radio :value="false">Non-truthful coincidence</b-form-radio>
							</b-form-radio-group>
						</b-form-group>



						<div class="row mb-3" v-if="pepConfirm">
							<div class="col-4">
								<label class="fw-medium mb-1" for="idType">Identification Type</label>
								<b-form-select id="idType" required v-model="idType" :options="[
									{ text: 'Passport', value: 'passport' },
									{ text: 'ID', value: 'id' },
								]"></b-form-select>
							</div>
							<div class="col-4">
								<label class="fw-medium mb-1" for="identification">No Identification</label>
								<b-form-input id="identification" type="text" placeholder="Number of ID" required
									v-model="identification"></b-form-input>
							</div>
							<div class="col-4 ">
								<label class="fw-medium mb-1" for="exp">Expiration Date</label>
								<b-form-input id="exp" type="date" placeholder="Exp. date for document" required
									v-model="expDate"></b-form-input>
							</div>
						</div>

						<div class="row">
							<div class="col-6">
								<label class="fw-medium mb-1" for="risk">Type of risk</label>
								<b-form-select id="risk" v-model="typeRisk" required :options="[
									{ value: null, text: 'Please select an option' },
									{ text: 'Low risk', value: 1 },
									{ text: 'Medium risk', value: 2 },
									{ text: 'High risk', value: 3 },
								]"></b-form-select>
							</div>
							<div class="mb-3 col-6">
								<label for="description" class="fw-medium">Describe your decision</label>
								<b-form-textarea id="description" placeholder="Describe decision..." rows="3"
									max-rows="6" v-model="decision"></b-form-textarea>
							</div>

						</div>

						<div class="d-flex justify-content-end">
							<div class="btn-grp text-right d-flex align-items-end h-100">
								<b-button pill :disabled="loadingPep" variant="primary" class="px-4 mb-0" type="submit">
									<b-spinner small v-if="loadingPep"></b-spinner>
									<span v-else>Save</span>
								</b-button>
							</div>
						</div>

					</b-form>

				</div>
			</div>

			<div class="row" v-if="scans.length <= 0">
				<div class="col-12 py-5">
					<h5 class="text-center">There are no records to show</h5>
				</div>
			</div>

			<div class="row">
				<div class="col-12 mb-4" v-for="item in scans" :key="item.id">
					<article class="bg-white p-3 h-100 rounded-lg">
						<div class="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
							<h4 class="mb-0">UID #{{ item.uniqueId }}</h4>
							<span class="bg-light p-2 text-dark rounded-lg">Match Rate | <strong>{{ item.matchRate
									}}</strong></span>
						</div>
						<div class="row">
							<div class="col-4">
								<div class="row">
									<div class="col-6">
										<!-- fisrtname, id, lastname, primary location, Deceased, Gender, Categories detail, Match Rate, Date of Birth -->
										<div class="py-2 d-flex justify-content-start align-items-center">
											<span class="bg-light mr-2 p-2 rounded-circle d-flex align-items-center">
												<feather type="user" class="feather-sm"></feather>
											</span>
											<div>
												<h6 class="mb-1">Name</h6>
												<p class="mb-0">{{ item.primaryFirstName }} {{ item.primaryLastName }}
												</p>
											</div>
										</div>
										<div class="py-2 d-flex justify-content-start align-items-center">
											<span class="bg-light mr-2 p-2 rounded-circle d-flex align-items-center">
												<feather type="map-pin" class="feather-sm"></feather>
											</span>
											<div>
												<h6 class="mb-1">Primary Location</h6>
												<p class="mb-0">{{ item.primaryLocation }}</p>
											</div>
										</div>
									</div>
									<div class="col-6">
										<div class="py-2 d-flex justify-content-start align-items-center">
											<span class="bg-light mr-2 p-2 rounded-circle d-flex align-items-center">
												<feather type="info" class="feather-sm"></feather>
											</span>
											<div>
												<h6 class="mb-1">Deceased</h6>
												<p class="mb-0">{{ item.deceased }}</p>
											</div>
										</div>
										<div class="py-2 d-flex justify-content-start align-items-center">
											<span class="bg-light mr-2 p-2 rounded-circle d-flex align-items-center">
												<feather type="user-check" class="feather-sm"></feather>
											</span>
											<div>
												<h6 class="mb-1">Gender</h6>
												<p class="mb-0">{{ item.gender }}</p>
											</div>
										</div>
										<div class="py-2 d-flex justify-content-start align-items-center">
											<span class="bg-light mr-2 p-2 rounded-circle d-flex align-items-center">
												<feather type="calendar" class="feather-sm"></feather>
											</span>
											<div>
												<h6 class="mb-1">Date of Birth</h6>
												<p class="mb-0">{{ item.dateOfBirth }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-8">
								<div class="p-3 bg-light rounded-lg my-2">
									<h6 class="mb-1">Categories Details</h6>
									<p class="mb-0">{{ item.categories }}</p>
								</div>

								<div class="p-3 bg-light rounded-lg my-2">
									<h6 class="mb-1">Further Information</h6>
									<p class="mb-0">{{ item.furtherInformation }}</p>
								</div>
							</div>
						</div>
					</article>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header.vue";
export default {
	components: {
		Header,
	},
	name: "MemberCheck",
	props: {
		new: Boolean,
		readOnly: Boolean,
	},
	data: () => ({
		firstName: "",
		lastName: "",
		loading: false,
		scans: [],
		selectedMatch: null,
		loadingPep: false,
		maritalStatusArray: [
			{
				id: 1,
				name: "Casa",
			},
			{
				id: 2,
				name: "Soltero/-a",
			},
			{
				id: 3,
				name: "Biba Hunto",
			},
			{
				id: 4,
				name: "Otro",
			},
		],
		pep: false,
		pepConfirm: null,
		typeRisk: null,
		decision: '',
		idType: null,
		identification: '',
		expDate: ''

	}),
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
		}),
		maritalStatus() {
			return this.maritalStatusArray.find(el => el.id == this.customer?.additionalDetails?.maritalStatusId)
		},
	},
	methods: {
		async validateMember() {
			this.loading = true;
			if (this.$route.path.includes("/loans/applications")) {
				await this.$store.dispatch("scans/memberVerification", {
					firstName: this.firstName,
					lastName: this.lastName,
					customerId: this.customer.id,
					loanApplicationId: this.$route.params.customer,
				});
			} else {
				await this.$store.dispatch("scans/memberVerification", {
					firstName: this.firstName,
					lastName: this.lastName,
					customerId: this.$route.params.customer,
				});
			}
			await this.getData();
			this.firstName = "";
			this.lastName = "";
			this.loading = false;
		},
		async getData() {
			this.loading = true;
			this.scans = await this.$store.dispatch("scans/get_scanById", this.$route.query.customerId);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.params.customer);

			this.firstName = this.customer.firstName;
			this.lastName = this.customer.lastName;
			this.loading = false;
		},

		setData() {
			console.log(this.customer)
			this.typeRisk = this.customer.riskTypeId
			this.pep = this.customer.pep
			this.pepConfirm = this.customer.peP_Confirmed
			if (this.customer.peP_Form) {
				this.idType = this.customer.peP_Form.identificationTypeId
				this.identification = this.customer.peP_Form.idNumber
				this.expDate = this.customer.peP_Form.expirationDate
				this.decision = this.customer.peP_Form.decisionSummary
			}
		},

		async updatePepData() {
			const documents = [{ text: 'Passport', value: 'passport' }, { text: 'ID', value: 'id' }]
			const idName = documents.find(el => el.value == this.idType)
			let pepForm = null

			if (this.pepConfirm) {
				pepForm = {
					identificationTypeId: this.idType,
					identificationTypeName: idName.text,
					idNumber: this.identification,
					expirationDate: this.expDate,
					decisionSummary: this.decision,
				}
			}


			let payload = {
				customerId: this.customer.id,
				riskTypeId: this.typeRisk,
				pep: this.pep,
				peP_Confirmed: this.pepConfirm,
				pepForm,
			}

			await this.$store.dispatch("customers/update_pepInformation", payload);

			console.log(payload)
		}
	},
	watch: {
		customer: function () {
			this.setData()
		}
	},
	created() {
		if (!this.new) {
			this.getData();
		}
	},
};
</script>
