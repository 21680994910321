<template>
	<b-row>
		<b-col cols="12">
			<b-card no-body class="mb-4">
				<b-card-body class="p-3">
					<Header />
					<hr class="m-0" />
					<div class="p-4 border-bottom my-5">
						<h4>Main Details</h4>
						<Main readOnly />
						<h4>Additional Details</h4>
						<AditionalDetails readOnly />
						<div class="d-flex justify-content-end mt-3">
							<b-button :disabled="loading" :variant="process.additionalDetails ? 'danger' : 'primary'"
								@click="updateStatus('AdditionalDetails', !process.additionalDetails)">{{
									process.additionalDetails ? "Revoke" : "Verify" }}</b-button>
						</div>
					</div>

					<div class="p-4 border-bottom my-5">
						<h4>Documents</h4>
						<ScannedDocuments readOnly />
					</div>

					<div class="p-4 border-bottom my-5">
						<h4>Comments</h4>
						<Comments readOnly />
					</div>

					<div class="p-4 border-bottom my-5">
						<h4>Employments</h4>
						<Employment readOnly />
						<div class="d-flex justify-content-end mt-3">
							<b-button :disabled="loading" :variant="process.jobVerification ? 'danger' : 'primary'"
								@click="updateStatus('JobVerification', !process.jobVerification)">{{
									process.jobVerification ? "Revoke" : "Verify" }}</b-button>
						</div>
					</div>
					<div class="p-4 border-bottom my-5">
						<h4>MemberCheck</h4>
						<Membercheck readOnly />
						<div class="d-flex justify-content-end mt-3">
							<b-button :disabled="loading" :variant="process.membercheck ? 'danger' : 'primary'"
								@click="updateStatus('Membercheck', !process.membercheck)">{{ process.membercheck ?
									"Revoke" : "Verify" }}</b-button>
						</div>
					</div>
					<div class="p-4 border-bottom my-5">
						<h4>Screening</h4>
						<Screening readOnly />
						<div class="d-flex justify-content-end mt-3">
							<b-button :disabled="loading" :variant="process.screening ? 'danger' : 'primary'"
								@click="updateStatus('Screening', !process.screening)">{{
									process.screening ? "Revoke" : "Verify"
								}}</b-button>
						</div>
					</div>

					<div class="d-flex justify-content-end mt-3 p-4">
						<b-button :disabled="loading" :variant="process.verifyAllInformation ? 'danger' : 'primary'"
							@click="updateStatus('VerifyAllInformation', !process.verifyAllInformation)">{{
								process.verifyAllInformation ? "Revoke" : "Verify all Information" }}</b-button>
					</div>
				</b-card-body>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import Main from "@/components/customers/Main.vue";
import AditionalDetails from "@/components/customers/AditionalDetails.vue";
import ScannedDocuments from "@/components/customers/ScannedDocuments.vue";
import Comments from "@/components/customers/Comments.vue";
import Employment from "@/components/customers/Employment.vue";
import Membercheck from "@/components/customers/MemberCheck.vue";
import Screening from "../../components/customers/Screening.vue";
import Header from "./Header.vue";
import { mapGetters } from "vuex";
export default {
	name: "Customer",
	components: {
		Main,
		AditionalDetails,
		ScannedDocuments,
		Comments,
		Employment,
		Membercheck,
		Screening,
		Header,
	},
	data: () => ({
		loading: false,
	}),
	methods: {
		async getData() {
			await this.$store.dispatch("customers/get_customer", this.$route.query.customerId);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.params.customer);
		},
		async updateStatus(service, status) {
			this.loading = true;
			const action = `productRequest/update_status${service}`;
			await this.$store.dispatch(action, {
				id: this.$route.params.customer,
				status: status,
			});
			await this.getData();
			this.loading = false;
		},
	},
	computed: {
		...mapGetters({
			process: "productRequest/getProcess",
		}),
	},
	created() {
		this.getData();
	},
};
</script>
